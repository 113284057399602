import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Guy - this is the place where I plan to share my thoughts.`}<br parentName="p"></br>{`
`}{`We are currently in the middle of refactoring our whole UI and I wanted to document the road to architecting a large scale frontend app.`}<br parentName="p"></br>{`
`}{`In between I'll probably add a few thoughts that I either want to share or I think compliment the process.`}<br parentName="p"></br>{`
`}{`Cheers 🍻`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      